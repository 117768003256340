import React from 'react';

import * as buttonStyles from "./button.module.css"


const Button = ({ onClick, onMouseLeave, children, radius, btnStyle, width, type, opacity = 1, fontWeight = 400 }) => {
    let buttonClass = buttonStyles.buttonLight
    if (btnStyle === "light") {
        buttonClass = buttonStyles.buttonLight
    } else if (btnStyle === "light2") {
        buttonClass = buttonStyles.buttonLight2
    }else if (btnStyle === "dark") {
        buttonClass = buttonStyles.buttonDark
    }
    return (
        <>
            {
                <button
                    className={buttonClass}
                    style={
                        {
                            borderRadius: radius,
                            borderStyle: 'solid',
                            width: width,
                            type: type,
                            opacity: opacity,
                            fontWeight: fontWeight,
                        }
                    } onClick={onClick} onMouseLeave={onMouseLeave}>
                    {children}
                </button>
            }
        </>
    );
};

export default Button;
